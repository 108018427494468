import React, { PureComponent, ReactNode, SyntheticEvent, ImgHTMLAttributes, MouseEventHandler } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { throttle } from 'lodash'
import { GridList, GridListTile } from '@material-ui/core'

import { ImageNode } from '../pages/gallery'
import { WindowLocation } from '@reach/router'

const Grid = styled(GridList)`
  > li {
    margin-bottom: 0;

    *:last-child {
      height: 100%;
      overflow: visible;
      border-radius: 3px;
    }
  }
`

const Image = styled(Img)`
  &&& {
    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 10px -2px black;
    }
  }
`

interface ImageGalleryProps {
    images: ImageNode[] | null,
    toggleImage: (event: React.MouseEvent<any>) => void
}

interface ImageGalleryState {
    isMobile: boolean
}

export default class ImageGallery extends PureComponent<ImageGalleryProps, ImageGalleryState> {
    constructor(props: ImageGalleryProps) {
        super(props)
        this.resize = throttle(this.resize.bind(this), 200)

        this.state = {
            isMobile: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize)
        this.resize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize() : void {
        this.setState({
            isMobile: window.innerWidth < 600
        })
    }

    render() {
        const numberOfColumns = this.state.isMobile ? 1 : 5

        return(
            <Grid 
                style={{
                width: '90vw', 
                margin:'0 auto 40px auto'
                }}
                cols={numberOfColumns}>
                {this.props.images !== null ? this.props.images.map((node, index) =>  {
                    node = node.node
                    return (
                        <GridListTile key={`image-${index}`} cols={1} onClick={this.props.toggleImage}>
                            <Image 
                                key={`image-${index}`} 
                                fluid={node.childImageSharp.fluid}/>
                        </GridListTile>
                    )
                }) : null}
            </Grid>
        )
    }
}